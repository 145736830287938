// functions
function goToSection__scroll(elScrollDestiny, distanceIfScrollGoingToUp, distanceIfScrollGoingToDown, speedScroll, delay) {
    console.clear();
    // scroll reference
    var scrollEfetuado = $(window).scrollTop();
    console.log(scrollEfetuado);
    // element reference
    var sectionDestiny_offsetTop = $(elScrollDestiny).offset().top;
    console.log(elScrollDestiny);
    console.log(sectionDestiny_offsetTop);

    if (scrollEfetuado > sectionDestiny_offsetTop) {
        console.log('vai subir');
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: sectionDestiny_offsetTop - distanceIfScrollGoingToUp
            }, speedScroll);
        }, delay);
    } else {
        console.log('vai descer');
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: sectionDestiny_offsetTop - distanceIfScrollGoingToDown
            }, speedScroll);
        }, delay);
    }
}
// Example:
// $('.js-linkScroll').on('click', function(e) {
//     e.preventDefault();
//     var finalDestiny = $(this).attr('href');
//     goToSection__scroll_v2(finalDestiny, 100, 50, 700, 10);
// });

$('aside a').on('click', function(e) {
    e.preventDefault();
    var finalDestiny = $(this).attr('href');
    var cardEffectId = finalDestiny.split('#');
    console.log(cardEffectId);
    $('.c-cardCampanha').removeClass('is-active');
    $(".c-cardCampanha[id="+cardEffectId[1]+"]").addClass('is-active');
    goToSection__scroll(finalDestiny, 100, 50, 700, 10);
});

$('[data-fancybox]').fancybox({
    thumbs : {
        autoStart : true
    }
});

